import React from "react"
import SEO from "../components/seo"

const ThanksPage = () => (
  <>
    <SEO title="Thanks" />
    <h1>Thanks!</h1>
    <p>We'll get back to you within five (5) business days.</p>
  </>
)

export default ThanksPage
